var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"createJourneyTask"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[(_vm.type === 'total')?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Obra',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Obra',"label-for":"job-project"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectProjects,"placeholder":'Obra'},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4238048501)})],1):_vm._e(),(_vm.type === 'total')?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Trabajo',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Trabajo',"label-for":"task-jobs"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectJobs,"placeholder":'Trabajo',"disabled":!_vm.project},model:{value:(_vm.jobForm),callback:function ($$v) {_vm.jobForm=$$v},expression:"jobForm"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4071042298)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Usuarios',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Usuarios',"label-for":"journey-users"}},[_c('v-select',{attrs:{"label":"name","multiple":"","filterable":true,"searchable":true,"options":_vm.selectUsers,"placeholder":'Usuarios'},model:{value:(_vm.users),callback:function ($$v) {_vm.users=$$v},expression:"users"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Estado',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Estado',"label-for":"task-status"}},[_c('v-select',{attrs:{"filterable":false,"searchable":false,"options":_vm.selectStatus,"placeholder":'Estado'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusTasksText[option.label])+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusTasksText[option.label])+" ")]}}],null,true),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Fecha de inicio',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Fecha de inicio',"label-for":"task-fechaini"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"date_ini","placeholder":"dd/mm/YYYY","config":_vm.configIni},model:{value:(_vm.date_ini),callback:function ($$v) {_vm.date_ini=$$v},expression:"date_ini"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Fecha de fin',"label-for":"task-fechafin"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"date_fin","placeholder":"dd/mm/YYYY","config":_vm.configFin},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1)],1),(!_vm.isMobile())?_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":'Descripcion',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Descripcion',"label-for":"task-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1751230756)})],1):_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":'Descripcion',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Descripcion',"label-for":"task-description2"}},[_c('b-form-textarea',{attrs:{"name":"Descripcion","placeholder":"Descripción","rows":"3","max-rows":"6"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":'Documentos',"label-for":"task-documents"}},[_c('ImageDropzone',{ref:"documents",attrs:{"files":_vm.files,"max-files":"10"}})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s("Enviar")+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }