<template>
  <div>
    <!-- form -->
    <validation-observer ref="editTask">
      <b-form @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Usuario'"
              rules="required"
            >
              <b-form-group :label="'Usuario'" label-for="task-user">
                <v-select
                  v-model="task.user"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectUsers"
                  :placeholder="'Usuario'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Estado'"
              rules="required"
            >
              <b-form-group :label="'Estado'" label-for="task-status">
                <v-select
                  v-model="task.status"
                  :filterable="false"
                  :searchable="false"
                  :options="selectStatus"
                  :placeholder="'Estado'"
                >
                  <template slot="option" slot-scope="option">
                    {{ statusTasksText[option.label] }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ statusTasksText[option.label] }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Fecha de inicio'"
              rules="required"
            >
              <b-form-group :label="'Fecha'" label-for="task-fechaini">
                <flat-pickr
                  v-model="day"
                  name="day"
                  class="form-control"
                  placeholder="dd/mm/YYYY"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="'Horas imputadas'" label-for="task-hours">
              <b-form-timepicker
                  v-model="hours"
                ></b-form-timepicker>    
            </b-form-group>  
          </b-col>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="'Descripcion'"
              rules="required"
            >
              <b-form-group :label="'Descripcion'" label-for="task-description">
                <quill-editor v-model="task.description" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="'Documentos'"
              label-for="task-documents"
              v-if="taskPrepare"
            >
              <ImageDropzone ref="documents" :files="files" maxFiles="10" />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ "Enviar" }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BButton, BForm, BFormGroup, BRow, BCol, BFormTimepicker } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {
  FlatPickrToTimestamp,
  TimestampToFlatPickrWithTime,
  FlatPickrToTimestampWithTime,
  TimestampToFlatPickrOnlyTime,
} from "@/libs/helpers";
import flatPickr from "vue-flatpickr-component";
import { config } from "@/shared/app.config";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    flatPickr,
    ImageDropzone,
    BFormTimepicker,
  },
  props: {
    job: Object,
    task: Object,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusTasksVariants: config.statusTasksVariants,
      statusTasksText: config.statusTasksText,
      required,
      email,
      name: "",
      user: "",
      description: "",
      day: "",
      status: "",
      hours: "",
      files: [],
      taskPrepare: false,
    };
  },
  computed: {
    ...mapGetters({
      selectStatus: "tasks/getSelectStatus",
      selectUsers: "users/getSelectUsers",
    }),
  },
  methods: {
    ...mapActions({
      edit: "tasks/edit",
      getSelectStatus: "tasks/getSelectStatus",
      getSelectUsers: "users/selectUsers",
    }),
    TimestampToFlatPickrOnlyTime(f) {
      return TimestampToFlatPickrOnlyTime(f);
    },
    FlatPickrToTimestampWithTime(f) {
      return FlatPickrToTimestampWithTime(f);
    },
    handleSubmit() {
      this.$refs.editTask.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id: this.task.id, journey: formData });
          }
        }
      });
    },
    createFormData() {
      const data = this.$refs.documents.getFormData("documents");
      data.append("day", FlatPickrToTimestamp(this.day));
      data.append("user_id", this.task.user.id);
      data.append("task_id", this.job.id);
      data.append("status", this.task.status);
      data.append("description", this.task.description);
      data.append('total_hours', this.hours)

      return data;
    },
    setData() {
      if (this.task.day) {
        this.day = TimestampToFlatPickrWithTime(this.task.day);
      }
      if (this.task.documents.length > 0) {
        const aux = this;
        this.task.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/journeytasks/delete_doc/${aux.task.id}/${element.id}`,
          };
          this.files.push(file);
        });
      }
      if(this.task.total_hours){
        this.hours = this.task.total_hours;
      }
      this.taskPrepare = true;
    },
  },
  async created() {
    await this.getSelectStatus();
    await this.getSelectUsers({ roles: ["operario"] });
    await this.setData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
