<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Perfil
      </h2>
      <b-button
        variant="primary"
        size="sm"
        @click="clickOpenJourney()"
        v-if="canRestartJourney"
      >
        Reanudar jornada
      </b-button>
    </div>
    <b-row>
      <b-col cols="12" >
        <b-card class="height-card" >
          <b-row class="mb-3" >
            <b-col xl="5" md="8" class="d-flex align-items-center" >
              <div class="pr-2" >
                <b-avatar
                  size="6rem"
                  :src="user.avatar"
                />
              </div>
              <div>
                <h4 class="m-0 font-weight-bolder" >{{ user.name }} {{ user.surname }}</h4>
                <span :key="r.display_name" v-for="r in user.roles" >
                  {{ r.display_name }}
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row class="position-relative" >
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >PUESTO DE TRABAJO</p>
              <p class="font-weight-bolder" >{{ user.job }}</p>
            </b-col>
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >DEPARTAMENTOS</p>
              <p class="font-weight-bolder" >
                <span :key="d.name" v-for="(d, i) in user.departments" ><span v-if="i !== 0" >, </span>{{ d.name }}</span>
              </p>
            </b-col>
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >EMAIL</p>
              <p class="font-weight-bolder" >{{ user.email }}</p>
            </b-col>
            <b-col xl="3" md="6">
              <p class="mb-50 title-style" >TELÉFONO</p>
              <p class="font-weight-bolder" >{{ user.phone }}</p>
            </b-col>
            <b-col cols="12" class="text-right" >
              <b-link
                :to="{ name: 'editUser', params: { id: user.id} }"
                class="d-inline-block text-dark pt-2"
              >
                <span class="">Editar</span>
                <span class=" pl-1"><feather-icon icon="Edit2Icon" /></span>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <List
      v-if="userPrepare"
      :stadistics="true"
      :userSet="user"
    />
    <Partes
      v-if="userPrepare"
      :user="user"
    />
    <Absences
      v-if="userPrepare"
      :user="user"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BButton, BAvatar, BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import List from '@/views/tasks/List'
import Partes from '@/views/partes/Operario'
import Absences from '@/views/users/Absences.vue'

export default {
  components: {
    Absences,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    List,
    BLink,
    Partes,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      companies: '',
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      changePassword: false,
      collapseInfo: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      checkJourney: 'users/getCheckJourney',
    }),
    canRestartJourney() {
      if (this.checkJourney.length && this.checkJourney[0].hour_fin !== null) {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
      getCheckJourney: 'users/checkJourney',
      restartJourney: 'users/restartJourney',
    }),
    ...mapMutations({
      setSelectDepartments: 'departments/setSelectDepartments',
    }),
    setClients() {
      if (this.user.clients) {
        this.clients = this.user.clients
      }
    },
    setDepartments() {
      if (this.user.departments[0]) {
        this.user.departments.forEach(element => {
          this.departments.push(element.id)
        })
      }
    },
    clickOpenJourney() {
      this.restartJourney({ user: this.user.id })
    },
  },
  async created() {
    await this.getUser(this.$route.params.id)
    await this.setDepartments()
    await this.getCheckJourney({ user: this.$route.params.id })
    this.userPrepare = true
  },
}
</script>
