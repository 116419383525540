<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div
          class="d-flex justify-content-between align-items-center pt-50 mb-2"
        >
          <div>
            <h2 class="font-weight-bolder m-0">
              Partes de horas
            </h2>
          </div>
        </div>
        <b-card no-body>
          <div
            class="px-1 pb-1 pt-1 d-flex align-items-center justify-content-between"
          >
            <div>
              <b-button
                variant="danger-light"
                :class="typeList == 'daily' ? 'active' : null"
                @click="typeList = 'daily'"
              >
                Diario
              </b-button>
              <b-button
                variant="danger-light"
                :class="typeList == 'monthly' ? 'active' : null"
                @click="typeList = 'monthly'"
              >
                Mensual
              </b-button>
            </div>
            <div class="px-2 align-items-center">
              <b-row>
                <month-picker-input
                  v-if="typeList == 'monthly'"
                  v-model="dateMontly"
                  :no-default="true"
                  lang="es"
                  :default-month="defaultMonth"
                  :default-year="defaultYear"
                  class="month-picker-partes"
                />
                <flat-pickr
                  v-if="typeList == 'daily'"
                  v-model="date"
                  name="date"
                  class="form-control"
                  placeholder="dd/mm/YYYY"
                />
              </b-row>
              <b-row>
                <b-input-group
                  v-if="!user"
                  class="input-group mt-1"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchTerm"
                    type="text"
                    class="d-inline-block"
                    :placeholder="'Búsqueda'"
                    @keydown.enter.native="handleSearch"
                  />
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 ml-1"
                    @click="handleSearch"
                  >
                    {{ "Buscar" }}
                  </b-button>
                </b-input-group>
              </b-row>
            </div>
          </div>
          <div
            v-if="typeList == 'monthly'"
            class="text-right"
          >
            <b-button
              v-if="!user"
              variant="primary"
              size="sm"
              class="mr-1"
              @click="clickGenerateMonthly()"
            >
              Generar informes
            </b-button>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columnsSet"
            class="table-white pt-1"
          >
            <template #cell(status)="data">
              <b-badge :variant="statusJourneyDayVariants[data.item.status]">
                {{ statusJourneyDayText[data.item.status] }}
              </b-badge>
            </template>
            <template #cell(avatar)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="md"
                  :src="data.item.avatar"
                />
                <b-link
                  :to="{ name: 'viewUser', params: { id: data.item.id } }"
                  class="link"
                >
                  <span class="ml-1">{{ data.item.name }}</span>
                </b-link>
              </div>
            </template>
            <template #cell(day.hour_ini)="data">
              {{ TimestampToFlatPickrOnlyTime(data.item.day.hour_ini) }}
              <span
                v-if="data.item.day.department"
                class="font-small"
              >
                - {{ data.item.day.department.name }}</span>
            </template>
            <template #cell(day.hour_fin)="data">
              {{ TimestampToFlatPickrOnlyTime(data.item.day.hour_fin) }}
            </template>
            <template #cell(hours)="data">
              <span v-if="typeList == 'daily'">{{
                data.item.total_horas_day
              }}</span>
              <span v-if="typeList == 'monthly'">{{
                data.item.month.horas_imputadas
              }}</span>
            </template>
            <template #cell(actions)="data">
              <span>
                <a
                  v-if="typeList == 'monthly' && data.item.pdf_month"
                  :href="urlExcelMonth(data.item.id)"
                  target="_blank"
                  download
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="PrinterIcon" />
                </a>
                <a
                  v-if="typeList == 'daily' && data.item.pdf_day"
                  :href="urlExcelDay(data.item.id)"
                  target="_blank"
                  download
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="PrinterIcon" />
                </a>
                <a
                  v-if="typeList == 'daily' && data.item.pdf_day"
                  :href="data.item.pdf_day"
                  target="_blank"
                  download
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="DownloadIcon" />
                </a>
                <a
                  v-if="typeList == 'monthly' && data.item.pdf_month"
                  :href="data.item.pdf_month"
                  target="_blank"
                  download
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="DownloadIcon" />
                </a>

                <a
                  v-if="typeList == 'daily'"
                  class="d-inline-block px-50 text-dark"
                  @click="showModal(data.item)"
                >
                  <feather-icon icon="Edit2Icon" />
                </a>
              </span>
            </template>
          </b-table>
          <div
            v-if="!user"
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="cambiar-jornada"
      v-model="modalShow"
      :title="'Editar jornada '+user_name"
      size="md"
      hide-footer
    >
      <validation-observer ref="updateJourneyDay">
        <b-form
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row class="d-flex justify-content-center">
            <b-col
              sm="10"
              class="pt-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="'Hora de inicio'"
                rules="required"
              >
                <b-form-group
                  :label="'Hora de inicio'"
                  label-for="task-fechaini"
                >
                  <b-form-timepicker
                    v-model="hour_ini"
                    locale="es"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="10">
              <b-form-group
                :label="'Hora de fin'"
                label-for="task-fechafin"
              >
                <b-form-timepicker
                  v-model="hour_fin"
                  :locale="locale"
                />

              </b-form-group>
            </b-col>
            <b-col sm="10">
              <b-form-group
                :label="'Horas extra'"
                label-for="task-extra"
              >
                <b-form-timepicker
                  v-model="hour_extra"
                  :locale="locale"
                />

              </b-form-group>
            </b-col>
             <b-col v-if="hour_fin && hour_ini" sm="10">
            <validation-provider
              #default="{ errors }"
              :name="'Estado'"
              rules="required"
            >
              <b-form-group :label="'Estado'" label-for="task-status">
                <v-select
                  v-model="status"
                  :filterable="false"
                  :searchable="false"
                  :options="selectStatus"
                  :placeholder="'Estado'"
                >
                  <template slot="option" slot-scope="option">
                    {{ statusJourneyDayText[option.label] }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ statusJourneyDayText[option.label] }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ "Enviar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BLink,
  BInputGroup,
  BInputGroupPrepend,
  BFormTimepicker,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import flatPickr from 'vue-flatpickr-component'
import {
  FlatPickrToTimestamp,
  TimestampToFlatPickrOnlyTime,
  FlatPickrToTimestampWithTime,
} from '@/libs/helpers'
import { MonthPicker, MonthPickerInput } from 'vue-month-picker'
import store from '@/store/index'
import { config } from '@/shared/app.config'
import vSelect from "vue-select";

export default {
  components: {
    BBadge,
    BAvatar,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    MonthPicker,
    MonthPickerInput,
    BFormTimepicker,
    BFormGroup,
    BForm,
    vSelect,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    let date = ''
    const d = new Date()
    date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    const defaultMonth = d.getMonth() + 1
    const defaultYear = d.getFullYear()
    const className = this.user ? 'd-none' : ''
    return {
      statusJourneyDayVariants: config.statusJourneyDayVariants,
      statusJourneyDayText: config.statusJourneyDayText,
      log: [],
      currentPage: 1,
      userSelect: '',
      defaultMonth,
      defaultYear,
      pageLength: 10,
      typeList: 'daily',
      dateMontly: '',
      dir: false,
      hour_ini: '',
      hour_fin: '',
      hour_extra: '',
      status: '',
      user_id: '',
      user_name: '',
      modalShow: false,
      date,
      timestampDateMonthly: '',
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Operario',
          key: 'avatar',
          class: className,
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Entrada',
          key: 'day.hour_ini',
        },
        {
          label: 'Salida',
          key: 'day.hour_fin',
        },
        {
          label: 'TRABAJADAS',
          key: 'total_horas_day',
        },
        {
          label: 'IMPUTADAS',
          key: 'day.horas_imputadas',
        },
        {
          label: 'EXTRA',
          key: 'day.extra_hours',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      columnsMonthly: [
        {
          label: 'Operario',
          key: 'avatar',
          class: className,
        },
        {
          label: 'TRABAJADAS',
          key: 'month.horas_trabajadas',
        },
        {
          label: 'IMPUTADAS',
          key: 'month.horas_imputadas',
        },
        {
          label: 'EXTRA',
          key: 'month.horas_extra',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
      locale: 'es',
      labels: {
        es: {
          labelHours: 'Stunden',
          labelMinutes: 'Minuten',
          labelSeconds: 'Sekunden',
          labelIncrement: 'Erhöhen',
          labelDecrement: 'Verringern',
          labelSelected: 'Ausgewählte Zeit',
          labelNoTimeSelected: 'Keine Zeit ausgewählt',
          labelCloseButton: 'Schließen',
        },
      },
    }
  },
  watch: {
    chargeList() {
      if (this.chargeList === 1) {
        this.chargeData()
        this.modalShow = false
      }
    },
    date() {
      this.chargeData()
    },
    dateMontly() {
      this.chargeData()
    },
    typeList() {
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      items: 'partes/getItemsOperarios',
      totalItems: 'partes/getTotalItemsOperarios',
      chargeList: 'partes/getChargeList',
      selectStatus: "partes/getSelectStatus",
    }),
    columnsSet() {
      if (this.typeList === 'monthly') {
        return this.columnsMonthly
      }
      return this.columns
    },
  },
  created() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'partes/getListOperarios',
      generateMonthly: 'partes/generateMonthly',
      edit: 'partes/edit',
      getSelectStatus: "partes/getSelectStatus",
    }),
    ...mapMutations({
      setChargeList: 'partes/setChargeList',
    }),
    async showModal(item) {
      this.user_id = item.id
      this.user_name = item.name
      this.hour_ini = this.TimestampToFlatPickrOnlyTime(item.day.hour_ini)
      this.hour_fin = this.TimestampToFlatPickrOnlyTime(item.day.hour_fin)
      this.hour_extra = item.day.extra_hours
      this.status = item.status
      this.modalShow = true
       await this.getSelectStatus();
    },
    async chargeData() {
      let timestamp = FlatPickrToTimestamp(this.date)

      if (this.typeList === 'monthly') {
        if (this.dateMontly === '') {
          timestamp = FlatPickrToTimestamp(
            `01/${this.defaultMonth}/${this.defaultYear}`,
          )
        } else {
          const d = this.dateMontly.from
          const date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
          timestamp = FlatPickrToTimestamp(date)
        }
        this.timestampDateMonthly = timestamp
      }
      let userId = ''
      if (this.user) {
        userId = this.user.id
      }
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        timestamp,
        user: userId,
      })
      this.setChargeList(0)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      console.log(params)
      this.chargeData()
    },
    TimestampToFlatPickrOnlyTime(f) {
      return TimestampToFlatPickrOnlyTime(f)
    },
    FlatPickrToTimestampWithTime(f) {
      return FlatPickrToTimestampWithTime(f)
    },
    async actionGenerateMonthly() {
      let timestamp = FlatPickrToTimestamp(this.date)
      if (this.typeList === 'monthly') {
        if (this.dateMontly === '') {
          timestamp = FlatPickrToTimestamp(
            `01/${this.defaultMonth}/${this.defaultYear}`,
          )
        } else {
          const d = this.dateMontly.from
          const date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
          timestamp = FlatPickrToTimestamp(date)
        }
      }
      await this.generateMonthly({ timestamp })
    },
    urlExcelDay(user) {
      return `${
        config.webApiBase
      }/journeyday/export/day/${user}/${FlatPickrToTimestamp(this.date)}`
    },
    urlExcelMonth(user) {
      return `${config.webApiBase}/journeyday/export/monthly/${user}/${this.timestampDateMonthly}`
    },
    async clickGenerateMonthly() {
      await this.actionGenerateMonthly()
      await this.chargeData()
    },
    handleSubmit() {
      this.$refs.updateJourneyDay.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.edit({ user: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('hour_ini', this.FlatPickrToTimestampWithTime(`${this.date} ${this.hour_ini}`))
      data.append('hour_fin', this.FlatPickrToTimestampWithTime(`${this.date} ${this.hour_fin}`))
      data.append('extra_hours', this.hour_extra)
      data.append('status', this.status)
      data.append('day', this.FlatPickrToTimestampWithTime(this.date))
      data.append('user_id', this.user_id)

      return data
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.month-picker--default{
  z-index: 1;
}
</style>
