<template>
  <b-row>
    <b-col sm="3">
      <b-card class="bg-info-fade height-card">
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">Tareas totales</strong>
        </h4>
        <h2 class="mb-0">
          <strong>{{ tasks }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card class="height-card bg-light-fade">
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">Horas totales</strong>
        </h4>
        <h2 class="mb-0">
          <strong>{{ hours }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card class="height-card bg-success-fade">
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">Tareas completadas</strong>
        </h4>
        <h2 class="mb-0">
          <strong>{{ complete }}</strong>
        </h2>
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-card class="height-card bg-danger-fade">
        <h4 class="mb-1 d-inline-block">
          <strong class="mr-1">Tareas con incidencia</strong>
        </h4>
        <h2 class="mb-0">
          <strong>{{ incidence }}</strong>
        </h2>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    user: {
      //   type: Number,
      default: 0,
    },
    client: {
      type: String,
      default: "",
    },
    day: {
      type: Number,
      default: 0,
    },
    project: {
      type: [Number, String],
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
  },
  components: {
    BCard,
    BCol,
    BRow,
  },
  data() {
    return {
      type: "line",
    };
  },
  watch: {
    user() {
      this.chargeData();
    },
    client() {
      this.chargeData();
    },
    project() {
      this.chargeData();
    },
    day() {
      this.chargeData();
    },
    status() {
      this.chargeData();
    },
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      stadistics: "tasks/getStadistics",
    }),
    tasks() {
      if (this.stadistics) {
        return this.stadistics.total_tasks ? this.stadistics.total_tasks : 0;
      }
      return 0;
    },
    hours() {
      if (this.stadistics) {
        return this.stadistics.total_hours ? this.stadistics.total_hours : 0;
      }
      return 0;
    },
    complete() {
      if (this.stadistics) {
        return this.stadistics.total_tasks_complete
          ? this.stadistics.total_tasks_complete
          : 0;
      }
      return 0;
    },
    incidence() {
      if (this.stadistics) {
        return this.stadistics.total_tasks_incidence
          ? this.stadistics.total_tasks_incidence
          : 0;
      }
      return 0;
    },
  },
  methods: {
    ...mapActions({
      getStadistics: "tasks/getStadistics",
    }),
    chargeData() {
      this.getStadistics({
        user: this.user,
        client: this.client,
        project: this.project,
        day: this.day,
        status: this.status,
      });
    },
  },
  async created() {
    let client = this.currentClient ? this.currentClient.id : "";
    if (this.clientprop) {
      client = this.clientprop;
    }
    await this.chargeData();
  },
};
</script>
<style lang="scss"></style>
