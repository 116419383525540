<template>
  <div>
    <!-- form -->
    <validation-observer ref="createJourneyTask">
      <b-form
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col
            v-if="type === 'total'"
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="'Obra'"
              rules="required"
            >
              <b-form-group
                :label="'Obra'"
                label-for="job-project"
              >
                <v-select
                  v-model="project"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectProjects"
                  :placeholder="'Obra'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="type === 'total'"
            sm="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="'Trabajo'"
              rules="required"
            >
              <b-form-group
                :label="'Trabajo'"
                label-for="task-jobs"
              >
                <v-select
                  v-model="jobForm"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectJobs"
                  :placeholder="'Trabajo'"
                  :disabled="!project"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Usuarios'"
              rules="required"
            >
              <b-form-group
                :label="'Usuarios'"
                label-for="journey-users"
              >
                <v-select
                  v-model="users"
                  label="name"
                  multiple
                  :filterable="true"
                  :searchable="true"
                  :options="selectUsers"
                  :placeholder="'Usuarios'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Estado'"
              rules="required"
            >
              <b-form-group
                :label="'Estado'"
                label-for="task-status"
              >
                <v-select
                  v-model="status"
                  :filterable="false"
                  :searchable="false"
                  :options="selectStatus"
                  :placeholder="'Estado'"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    {{ statusTasksText[option.label] }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ statusTasksText[option.label] }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="'Fecha de inicio'"
              rules="required"
            >
              <b-form-group
                :label="'Fecha de inicio'"
                label-for="task-fechaini"
              >
                <flat-pickr
                  v-model="date_ini"
                  name="date_ini"
                  class="form-control"
                  placeholder="dd/mm/YYYY"
                  :config="configIni"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="'Fecha de fin'"
              label-for="task-fechafin"
            >
              <flat-pickr
                v-model="date_fin"
                name="date_fin"
                class="form-control"
                placeholder="dd/mm/YYYY"
                :config="configFin"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isMobile()"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              :name="'Descripcion'"
              rules="required"
            >
              <b-form-group
                :label="'Descripcion'"
                label-for="task-description"
              >
                <quill-editor v-model="description" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-else
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              :name="'Descripcion'"
              rules="required"
            >
              <b-form-group
                :label="'Descripcion'"
                label-for="task-description2"
              >
                <b-form-textarea
                  v-model="description"
                  name="Descripcion"
                  placeholder="Descripción"
                  rows="3"
                  max-rows="6"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="'Documentos'"
              label-for="task-documents"
            >
              <ImageDropzone
                ref="documents"
                :files="files"
                max-files="10"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ "Enviar" }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { FlatPickrToTimestamp } from '@/libs/helpers'
import flatPickr from 'vue-flatpickr-component'
import { config } from '@/shared/app.config'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    flatPickr,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  props: {
    job: Object,
    task: Object,
    type: String,
  },
  data() {
    return {
      statusTasksVariants: config.statusTasksVariants,
      statusTasksText: config.statusTasksText,
      required,
      email,
      name: '',
      user: '',
      description: '',
      date_ini: this.job ? new Date(this.job.date_ini * 1000) : '',
      date_fin: this.job
        ? this.job.date_fin
          ? new Date(this.job.date_fin * 1000)
          : ''
        : '',
      status: '',
      files: [],
      users: [],
      configIni: {
        defaultDate: this.job ? new Date(this.job.date_ini * 1000) : '',
        minDate: '',
        maxDate: '',
      },
      configFin: {
        defaultDate: this.job
          ? this.job.date_fin
            ? new Date(this.job.date_fin * 1000)
            : ''
          : '',
        minDate: '',
        maxDate: '',
      },
      project: '',
      jobForm: this.job ? this.job : '',
      iniResta: '',
      finResta: '',
    }
  },
  computed: {
    ...mapGetters({
      selectStatus: 'tasks/getSelectStatus',
      selectUsers: 'users/getSelectUsers',
      selectProjects: 'projects/getSelectProjects',
      selectJobs: 'jobs/getItems',
    }),
  },
  watch: {
    date_ini() {
      this.configFin.minDate = this.date_ini
    },
    async project() {
      await this.getSelectJobs({
        client: '',
        page: 1,
        per_page: 10000,
        project: this.project.id,
      })
    },
  },
  methods: {
    ...mapActions({
      create: 'tasks/create',
      getSelectStatus: 'tasks/getSelectStatus',
      getSelectUsers: 'users/selectUsers',
      getSelectProjects: 'projects/getSelectProjects',
      getSelectJobs: 'jobs/getListJobs',
    }),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        return true
      }
      return false
    },
    isValidDate(date) {
      return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date))
    },
    handleSubmit() {
      this.$refs.createJourneyTask.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            const fecha1 = new Date(this.iniResta * 1000)
            const fecha2 = new Date(this.finResta * 1000)
            let resta = fecha2.getTime() - fecha1.getTime()
            resta = Math.round(resta / (1000 * 60 * 60 * 24))

            if (resta > 30) {
              this.days30MTask(resta, formData)
            } else {
              this.create({ journey: formData })
            }
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.documents.getFormData('documents')

      if (this.isValidDate(this.date_ini)) {
        if (typeof this.date_ini !== 'string') {
          data.append('date_ini', this.date_ini / 1000)
          this.iniResta = this.date_ini / 1000
        } else {
          data.append('date_ini', FlatPickrToTimestamp(this.date_ini))
          this.iniResta = FlatPickrToTimestamp(this.date_ini)
        }
      } else {
        data.append('date_ini', FlatPickrToTimestamp(this.date_ini))
        this.iniResta = FlatPickrToTimestamp(this.date_ini)
      }

      if (this.date_fin !== '') {
        if (this.isValidDate(this.date_fin)) {
          if (typeof this.date_fin !== 'string') {
            data.append('date_fin', this.date_fin / 1000)
            this.finResta = this.date_fin / 1000
          } else {
            data.append('date_fin', FlatPickrToTimestamp(this.date_fin))
            this.finResta = FlatPickrToTimestamp(this.date_fin)
          }
        } else {
          data.append('date_fin', FlatPickrToTimestamp(this.date_fin))
          this.finResta = FlatPickrToTimestamp(this.date_fin)
        }
      }

      if (this.users) {
        this.users.forEach(us => {
          data.append('users[]', us.id)
        })
      } else {
        data.append('users', '')
      }
      data.append('name', this.name)
      data.append('task_id', this.jobForm.id)
      data.append('status', this.status)
      data.append('description', this.description)

      return data
    },
    days30MTask(dias, formData) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro que quieres crear la tarea para ${dias} días?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Crear',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.create({ journey: formData })
          }
        })
    },
  },
  async created() {
    if (this.job) {
      const iniDate = new Date(this.job.date_ini * 1000) // date object
      const finDate = new Date(this.job.date_fin * 1000) // date object
      this.configIni.defaultDate = iniDate
      this.configIni.minDate = iniDate
      this.configIni.maxDate = finDate

      this.configFin.defaultDate = finDate
      this.configFin.minDate = iniDate
      this.configFin.maxDate = finDate
    } else {
      await this.getSelectProjects({})
    }
    if (this.task) {
      this.users.push(this.task.user)
    }

    await this.getSelectStatus()

    await this.getSelectUsers({ roles: ['operario'] })
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
