<template>
  <div>
    <!-- form -->
    <validation-observer ref="createAbsence">
      <b-form
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="'Motivo'"
              rules="required"
            >
              <b-form-group
                :label="'Motivo'"
                label-for="absence-tipo"
              >
                <v-select
                  v-model="tipo"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectTipo"
                  :placeholder="'Tipo'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="'Fecha de inicio'"
              rules="required"
            >
              <b-form-group
                :label="'Fecha de inicio'"
                label-for="absence-fechaini"
              >
                <flat-pickr
                  v-model="date_ini"
                  name="date_ini"
                  class="form-control"
                  placeholder="dd/mm/YYYY"
                  :config="configIni"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="'Fecha de fin'"
              label-for="absence-fechafin"
            >
              <flat-pickr
                v-model="date_fin"
                name="date_fin"
                class="form-control"
                placeholder="dd/mm/YYYY"
                :config="configFin"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ "Guardar" }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { FlatPickrToTimestamp } from '@/libs/helpers'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    user: Object,
    absence: Object,
  },
  data() {
    return {
      selectTipo: ['Baja', 'Vacaciones'],
      required,
      email,
      tipo: this.absence ? this.absence.type : '',
      date_ini: this.absence ? new Date(this.absence.date_ini * 1000) : '',
      date_fin: this.absence ? new Date(this.absence.date_fin * 1000) : '',
      configIni: {
        defaultDate: this.absence ? new Date(this.absence.date_ini * 1000) : '',
        minDate: '',
        maxDate: '',
      },
      configFin: {
        defaultDate: this.absence ? this.absence.date_fin ? new Date(this.absence.date_fin * 1000) : '' : '',
        minDate: '',
        maxDate: '',
      },
    }
  },
  watch: {
    date_ini() {
      this.configFin.minDate = this.date_ini
    },
  },
  methods: {
    ...mapActions({
      create: 'absences/create',
      edit: 'absences/edit',
    }),
    isValidDate(date) {
      return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date))
    },
    handleSubmit() {
      this.$refs.createAbsence.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            if (this.absence) {
              this.edit({ id: this.absence.id, absence: formData })
            } else {
              this.create({ absence: formData })
            }
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()

      if (this.isValidDate(this.date_ini)) {
        if (typeof this.date_ini !== 'string') {
          data.append('date_ini', this.date_ini / 1000)
        } else {
          data.append('date_ini', FlatPickrToTimestamp(this.date_ini))
        }
      } else {
        data.append('date_ini', FlatPickrToTimestamp(this.date_ini))
      }

      if (this.date_fin !== '') {
        if (this.isValidDate(this.date_fin)) {
          if (typeof this.date_fin !== 'string') {
            data.append('date_fin', this.date_fin / 1000)
          } else {
            data.append('date_fin', FlatPickrToTimestamp(this.date_fin))
          }
        } else {
          data.append('date_fin', FlatPickrToTimestamp(this.date_fin))
        }
      }
      data.append('type', this.tipo)
      data.append('user_id', this.user.id)

      return data
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
