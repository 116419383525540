var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"editTask"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Usuario',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Usuario',"label-for":"task-user"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectUsers,"placeholder":'Usuario'},model:{value:(_vm.task.user),callback:function ($$v) {_vm.$set(_vm.task, "user", $$v)},expression:"task.user"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Estado',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Estado',"label-for":"task-status"}},[_c('v-select',{attrs:{"filterable":false,"searchable":false,"options":_vm.selectStatus,"placeholder":'Estado'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusTasksText[option.label])+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusTasksText[option.label])+" ")]}}],null,true),model:{value:(_vm.task.status),callback:function ($$v) {_vm.$set(_vm.task, "status", $$v)},expression:"task.status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Fecha de inicio',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Fecha',"label-for":"task-fechaini"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"day","placeholder":"dd/mm/YYYY"},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Horas imputadas',"label-for":"task-hours"}},[_c('b-form-timepicker',{model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":'Descripcion',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Descripcion',"label-for":"task-description"}},[_c('quill-editor',{model:{value:(_vm.task.description),callback:function ($$v) {_vm.$set(_vm.task, "description", $$v)},expression:"task.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[(_vm.taskPrepare)?_c('b-form-group',{attrs:{"label":'Documentos',"label-for":"task-documents"}},[_c('ImageDropzone',{ref:"documents",attrs:{"files":_vm.files,"maxFiles":"10"}})],1):_vm._e()],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s("Enviar")+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }