<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
            class="d-flex justify-content-between align-items-center px-1 py-1"
          >
            <div class="col-6">
              <h3 class="m-0">
                {{ "Ausencias" }}
              </h3>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <!--  <b-link :to="{ name: 'createJob' }"> -->
                <b-button
                  v-b-modal="'crear-ausencia'"
                  variant="primary"
                  size="sm"
                  class="mr-1 text-nowrap"
                >
                  Añadir nueva ausencia
                </b-button>
                <!-- </b-link> -->
              </div>
              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </div>
          </div>
          <div>
            <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha inicio'"
                      label-for="filter-fechaini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha fin'"
                      label-for="filter-fechafin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
            :no-local-sorting="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(date_ini)="data">
              {{ dateColumn(data.item.date_ini) }} -
              {{ dateColumn(data.item.date_fin) }}
            </template>
            <template #cell(actions)="data">
              <span>
                <span
                  class="d-inline-block px-50 text-dark"
                  @click="openEditAbsence(data.item)"
                >
                  <feather-icon icon="Edit2Icon" />
                </span>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteAbsence(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="showEditAbsence"
      :hide-footer="true"
      title="Editar ausencia"
      size="lg"
    >
      <form-create-absence
        :user="user"
        :absence="absence"
      />
    </b-modal>
    <b-modal
      id="crear-ausencia"
      :title="'Crear Ausencia: ' + this.user.name"
      size="sm"
      hide-footer
    >
      <form-create-absence
        :user="user"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCollapse,
  BButton,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'
import FormCreateAbsence from '@/views/users/FormCreateAbsence.vue'
import FormEditTask from '@/views/tasks/FormEditTask'
import axios from 'axios'
import store from '@/store'

export default {
  components: {
    FormCreateAbsence,
    BTable,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    BRow,
    vSelect,
    BCol,
    BCollapse,
    FormEditTask,
    BButton,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: '',
      showCreateAbsence: false,
      showEditAbsence: false,
      absence: '',
      log: [],
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      status: '',
      searchTerm: '',
      date_ini: '',
      date_fin: '',
    }
  },
  computed: {
    ...mapGetters({
      items: 'absences/getItems',
      totalItems: 'absences/getTotalItems',
    }),
    columns() {
      return [
        {
          label: 'Motivo',
          key: 'type',
        },
        {
          label: 'Fechas',
          key: 'date_ini',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ]
    },
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    user() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.chargeData()
    },
    date_fin() {
      this.chargeData()
    },
    orderby() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  created() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'absences/getListAbsences',
      delete: 'absences/delete',
    }),
    ...mapMutations({
      loading: 'app/loading',
    }),
    chargeData() {
      let filterDateIni = ''
      let filterDateFin = ''

      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini)
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin)
      }
      let orderBy = ''
      if (this.sortBy) {
        const type = this.sortDesc ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }

      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        user_id: this.user.id,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        orderBy,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteAbsence(id) {
      this.$bvModal
        .msgBoxConfirm('¿Estás seguro de eliminar la ausencia?', {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    async openEditAbsence(absence) {
      this.absence = absence
      this.showEditAbsence = true
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
