<template>
  <div>
    <Stadistics
      v-if="stadistics"
      :user="userProp"
      :client="clientProp"
      :project="projectProp"
      :day="dayProp"
      :status="statusProp"
    />
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
            class="d-flex justify-content-between align-items-center px-1 py-50"
          >
            <div class="col-6">
              <h3 class="m-0">
                {{ "Tareas" }}
              </h3>
            </div>
            <div class="d-flex align-items-center">
              <b-input-group class="input-group">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  type="text"
                  class="d-inline-block"
                  :placeholder="'Búsqueda'"
                  @keydown.enter.native="handleSearch"
                />
                <b-button
                  variant="primary"
                  size="sm"
                  class="mr-1 ml-1"
                  @click="handleSearch"
                >
                  {{ "Buscar" }}
                </b-button>
              </b-input-group>
              <b-nav-item-dropdown
                  right
                  toggle-class="d-flex align-items-center dropdown-user-link"
                  class="dropdown-user"
              >
                <template #button-content>
                  <feather-icon
                      size="16"
                      icon="MoreHorizontalIcon"
                  />
                </template>

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    v-b-modal="'crear-tarea'"
                >
                  Añadir nueva tarea
                </b-dropdown-item>

                <b-dropdown-item
                    @click="download('excel')"
                >
                    Descargar lista Excel
                </b-dropdown-item>
                <b-dropdown-item
                    variant="primary"
                    link-class="d-flex align-items-center"
                    @click="eliminar()"
                >
                  <span> Eliminar tareas </span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="20"
                />
              </span>
            </div>
          </div>
          <div>
            <b-collapse
              id="collapse"
              v-model="visibleFilter"
              class="mt-2"
            >
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha inicio'"
                      label-for="filter-fechaini"
                    >
                      <flat-pickr
                        v-model="date_ini"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Fecha fin'"
                      label-for="filter-fechafin"
                    >
                      <flat-pickr
                        v-model="date_fin"
                        name="fecha_ini"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Estado'"
                      label-for="filter-order"
                    >
                      <v-select
                        v-model="status"
                        label="name"
                        :filterable="false"
                        :searchable="false"
                        :options="selectStatus"
                      >
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          {{ statusTasksText[option.name] }}
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          {{ statusTasksText[option.name] }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Usuario'"
                      label-for="filter-user"
                    >
                      <v-select
                        v-model="user"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectUsers"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Clientes'"
                      label-for="filter-client"
                    >
                      <v-select
                        v-model="client"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectClients"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="'Obra'"
                      label-for="filter-project"
                    >
                      <v-select
                        v-model="project"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="selectProjects"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table
            ref="myTable"
            hover
            responsive
            :items="items"
            :fields="columns"
            :no-local-sorting="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :select-mode="'multi'"
            @select-all="selectAll"
            @select="selectItem"
          >
            <template v-slot:head(selected)="data">
              <b-form-checkbox v-model="allSelected" @change="selectAll"></b-form-checkbox>
            </template>
            <template #cell(selected)="data">
              <b-form-checkbox
                  v-model="data.item.selected"
              />
            </template>
            <template #cell(day)="data">
              {{ dateColumn(data.item.day) }}
            </template>
            <template #cell(task)="data">
              <span v-if="data.item.task">
                <b-link
                  :to="{ name: 'viewJob', params: { id: data.item.task.id } }"
                  class="link"
                >{{ data.item.task.name }}</b-link>
              </span>
            </template>
            <template #cell(client_object)="data">
              <b-link
                v-if="data.item.client_object"
                :to="{
                  name: 'viewClient',
                  params: { id: data.item.client_object.id },
                }"
                class="link"
              >
                <span>{{ data.item.client_object.name }}</span>
              </b-link>
            </template>
            <template #cell(obra)="data">
              <p
                v-if="data.item.obra"
                class="m-0"
              >
                <b-link
                  :to="{
                    name: 'viewProject',
                    params: { id: data.item.obra.id },
                  }"
                  class="link"
                >
                  <span>{{ data.item.obra.name }}</span>
                </b-link>
              </p>
              <span
                v-if="data.item.obra && data.item.obra.SKU"
                class="font-small"
              >{{
                data.item.obra.SKU
              }}</span>
            </template>

            <template #cell(user)="data">
              <b-avatar
                v-if="data.item.user && data.item.user.avatar_url"
                size="md"
                :src="data.item.user.avatar_url"
              />
              <b-avatar
                v-else
                size="md"
              />
              <span class="ml-1">
                <b-link
                  v-if="data.item.user"
                  :to="{ name: 'viewUser', params: { id: data.item.user.id } }"
                  class="link"
                >
                  <span>{{ data.item.user.name }}</span>
                </b-link>
              </span>
            </template>
            <template #cell(category)="data">
              <span v-if="data.item.category">{{
                data.item.category.name
              }}</span>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusTasksVariants[data.item.status]">
                {{ statusTasksText[data.item.status] }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewTask', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <span
                  class="d-inline-block px-50 text-dark"
                  @click="openEditTask(data.item, data.item.task)"
                >
                  <feather-icon icon="Edit2Icon" />
                </span>
                <span
                  class="d-inline-block px-50 text-dark"
                  @click="openCreateTask(data.item, data.item.task)"
                >
                  <feather-icon icon="CalendarIcon" />
                </span>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteTask(data.item.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-if="this.task.obra && this.job"
      v-model="showCreateTask"
      :hide-footer="true"
      :title="'Crear tarea: ' + this.task.obra.name + '-' + this.job.name"
      size="lg"
    >
      <form-create-task
        :job="job"
        :task="task"
      />
    </b-modal>
    <b-modal
      v-else
      v-model="showCreateTask"
      :hide-footer="true"
      title="Crear tarea: "
      size="lg"
    >
      <form-create-task
        :job="job"
        :task="task"
      />
    </b-modal>
    <b-modal
      v-model="showEditTask"
      :hide-footer="true"
      title="Editar tarea"
      size="lg"
    >
      <form-edit-task
        :job="job"
        :task="task"
      />
    </b-modal>
    <b-modal
      id="crear-tarea"
      :title="'Crear tarea'"
      size="lg"
      hide-footer
    >
      <form-create-task type="total" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCollapse,
  BAvatar,
  BButton,
  BFormCheckbox,
  BNavItemDropdown,
  BDropdownDivider,
  BDropdownItem,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import flatPickr from 'vue-flatpickr-component'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'
import Stadistics from '@/views/tasks/Stadistics'
import FormCreateTask from '@/views/tasks/FormCreateTask'
import FormEditTask from '@/views/tasks/FormEditTask'
import axios from 'axios'
import store from '@/store'

export default {
  components: {
    BTable,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BDropdownDivider,
    BDropdownItem,
    flatPickr,
    BRow,
    BFormCheckbox,
    BNavItemDropdown,
    vSelect,
    BCol,
    BCollapse,
    FormCreateTask,
    FormEditTask,
    Stadistics,
    BAvatar,
    BButton,
  },
  props: {
    stadistics: {
      type: Boolean,
      default: true,
    },
    userSet: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: '',
      showCreateTask: false,
      showEditTask: false,
      job: {},
      task: {},
      log: [],
      statusTasksVariants: config.statusTasksVariants,
      statusTasksText: config.statusTasksText,
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      date: '',
      user: '',
      client: '',
      project: '',
      status: '',
      searchTerm: '',
      date_ini: '',
      date_fin: '',
      allSelected: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'tasks/getItems',
      totalItems: 'tasks/getTotalItems',
      selectStatus: 'tasks/getSelectStatus',
      selectUsers: 'users/getSelectUsers',
      selectClients: 'clients/getSelectClients',
      selectProjects: 'projects/getSelectProjects',
    }),
    columns() {
      let array = []
      if (this.userSet) {
        array = [
          {
            label: '',
            key: 'selected',
          },
          {
            label: 'Fecha',
            key: 'day',
            sortable: true,
          },
          {
            label: 'Obra',
            key: 'obra',
          },
          {
            label: 'Departamento',
            key: 'department.name',
          },
          {
            label: 'Trabajo',
            key: 'task',
          },
          {
            label: 'Estado',
            key: 'status',
          },
          {
            label: 'Usuario',
            key: 'user',
          },
          {
            label: 'Imputadas',
            key: 'total_hours',
            sortable: true,
          },
          {
            label: 'Acciones',
            key: 'actions',
            class: 'column-actions',
          },
        ]
      } else {
        array = [
          {
            label: 'Fecha',
            key: 'day',
            sortable: true,
          },
          {
            label: 'Usuario',
            key: 'user',
          },
          {
            label: 'Obra',
            key: 'obra',
          },
          {
            label: 'Trabajo',
            key: 'task',
          },
          {
            label: 'Estado',
            key: 'status',
          },
          {
            label: 'Imputadas',
            key: 'total_hours',
            sortable: true,
          },
          {
            label: 'Acciones',
            key: 'actions',
            class: 'column-actions',
          },
        ]
      }

      return array
    },
    userFilter() {
      if (this.userSet) {
        return false
      }
      return true
    },
    userProp() {
      if (this.user) {
        return this.user.id
      }
      return ''
    },
    clientProp() {
      if (this.client) {
        return this.client.id
      }
      return ''
    },
    projectProp() {
      if (this.project) {
        return this.project.id
      }
      return ''
    },
    dayProp() {
      if (this.date) {
        return FlatPickrToTimestamp(this.date)
      }
      return 0
    },
    statusProp() {
      if (this.status) {
        return this.status
      }
      return ''
    },
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    user() {
      this.currentPage = 1
      this.chargeData()
    },
    client() {
      this.currentPage = 1
      this.project = ''
      this.chargeProjects()
      this.chargeData()
    },
    project() {
      this.currentPage = 1
      this.chargeData()
    },
    date() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.chargeData()
    },
    date_fin() {
      this.chargeData()
    },
    orderby() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  created() {
    if (this.userSet && this.userSet.hasOwnProperty('active')) {
      this.user = this.userSet
    } else {
      this.pageLength = JSON.parse(localStorage.getItem('per_page')) || 10 // value or null
      this.user = JSON.parse(localStorage.getItem('user')) || '' // value or null
      this.searchTerm = JSON.parse(localStorage.getItem('search')) || '' // value or null
      this.currentPage = JSON.parse(localStorage.getItem('currentPage')) || 1 // value or null
      this.date_ini = JSON.parse(localStorage.getItem('date_iniTask')) || '' // value or null
      this.date_fin = JSON.parse(localStorage.getItem('date_finTask')) || '' // value or null
      this.status = JSON.parse(localStorage.getItem('status')) || '' // value or null
      this.sortBy = JSON.parse(localStorage.getItem('orderBy')) || '' // value or null
      this.project = JSON.parse(localStorage.getItem('project')) || '' // value or null
      this.client = JSON.parse(localStorage.getItem('client')) || '' // value or null
      this.visibleFilter = JSON.parse(localStorage.getItem('visibleFilter')) || false // value or null
    }
    this.chargeData();
    this.getSelectStatus();
    this.getSelectClients();
    this.chargeProjects();
    this.getSelectUsers({ roles: ["operario"] });
  },
  methods: {
    ...mapActions({
      list: 'tasks/getListTasks',
      delete: 'tasks/delete',
      deleteMultiple: 'tasks/deleteMultiple',
      getSelectStatus: 'tasks/getSelectStatus',
      getSelectUsers: 'users/selectUsers',
      getSelectClients: 'clients/selectClients',
      getSelectProjects: 'projects/getSelectProjects',
    }),
    ...mapMutations({
      loading: 'app/loading',
    }),
    selectAll(checked) {
      this.allSelected = checked
      this.items.forEach(item => (item.selected = checked))
    },
    selectItem(selectedItems) {
      this.allSelected = this.items.length === selectedItems.length
    },

    eliminar() {
      console.log( this.items.filter(item => item.selected).map(item => item.id))
      this.$bvModal
        .msgBoxConfirm('¿Estás seguro de eliminar las tareas seleccionadas?', {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.deleteMultiple(this.items.filter(item => item.selected).map(item => item.id))
          }
        })
    },
    download(type) {
      this.loading(true)
      const filterDay = ''
      let filterDateIni = ''
      let filterDateFin = ''
      let user = ''
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini)
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin)
      }
      if (this.user && this.user !== null) {
        user = this.user.id
      }
      let status = ''
      if (this.status && this.status !== null) {
        status = this.status
      }
      let client = ''
      if (this.client && this.client !== null) {
        client = this.client.id
      }
      let project = ''
      if (this.project && this.project !== null) {
        project = this.project.id
      }
      let orderBy = ''
      if (this.sortBy) {
        const type = this.sortDesc ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }
      axios({
        url: `${config.webApiBase}/journeytasks/list/download`, // your url
        data: {
          client_id: client,
          project_id: project,
          orderBy,
          status,
          user_id: user,
          date_ini: filterDateIni,
          date_fin: filterDateFin,
          search: this.searchTerm,
          type,
        },
        method: 'POST',
        responseType: 'blob', // important
      }).then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        if (type === 'excel') {
          link.setAttribute('download', 'list.xlsx') // or any other extension
        } else {
          link.setAttribute('download', 'list.pdf') // or any other extension
        }
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        this.loading(false)
      })
    },
    chargeProjects() {
      let client = ''
      if (this.client && this.client !== null) {
        client = this.client.id
      }
      this.getSelectProjects({ client })
    },
    chargeData() {
      if (this.userSet && !this.userSet.hasOwnProperty('active')) {
        localStorage.setItem('per_page', JSON.stringify(this.pageLength))
        localStorage.setItem('user', JSON.stringify(this.user))
        localStorage.setItem('currentPage', JSON.stringify(this.currentPage))
        localStorage.setItem('search', JSON.stringify(this.searchTerm))
        localStorage.setItem('date_iniTask', JSON.stringify(this.date_ini))
        localStorage.setItem('date_finTask', JSON.stringify(this.date_fin))
        localStorage.setItem('status', JSON.stringify(this.status))
        localStorage.setItem('orderBy', JSON.stringify(this.sortBy))
        localStorage.setItem('project', JSON.stringify(this.project))
        localStorage.setItem('client', JSON.stringify(this.client))
        localStorage.setItem('visibleFilter', JSON.stringify(this.visibleFilter))
      }

      const filterDay = ''
      let filterDateIni = ''
      let filterDateFin = ''
      let user = ''
      if (this.date_ini) {
        filterDateIni = FlatPickrToTimestamp(this.date_ini)
      }
      if (this.date_fin) {
        filterDateFin = FlatPickrToTimestamp(this.date_fin)
      }
      if (this.user && this.user !== null) {
        user = this.user.id
      }
      let status = ''
      if (this.status && this.status !== null) {
        status = this.status
      }
      let client = ''
      if (this.client && this.client !== null) {
        client = this.client.id
      }
      let project = ''
      if (this.project && this.project !== null) {
        project = this.project.id
      }
      let orderBy = ''
      if (this.sortBy) {
        const type = this.sortDesc ? 'desc' : 'asc'
        orderBy = {
          column: this.sortBy,
          type,
        }
      }

      this.list({
        user,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: filterDateIni,
        date_fin: filterDateFin,
        status,
        orderBy,
        project,
        client,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteTask(id) {
      this.$bvModal
        .msgBoxConfirm('¿Estás seguro de eliminar la tarea?', {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    async openEditTask(task, job) {
      this.task = task
      this.job = job
      this.showEditTask = true
    },

    async openCreateTask(task, job) {
      console.log(task)
      console.log(job)
      this.task = task
      this.job = job
      this.showCreateTask = true
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.dropdown-user{
  list-style-type: none;
}
[dir] .b-nav-dropdown .dropdown-toggle::after{
  display: none;
}
</style>
